export const HourlyConstants = [
  { value: "20", label: "$20" },
  { value: "21", label: "$21" },
  { value: "22", label: "$22" },
  { value: "23", label: "$23" },
  { value: "24", label: "$24" },
  { value: "25", label: "$25" },
  { value: "26", label: "$26" },
  { value: "27", label: "$27" },
  { value: "28", label: "$28" },
  { value: "29", label: "$29" },
  { value: "30", label: "$30" },
  { value: "31", label: "$31" },
  { value: "32", label: "$32" },
  { value: "33", label: "$33" },
  { value: "34", label: "$34" },
  { value: "35", label: "$35" },
  { value: "36", label: "$36" },
  { value: "37", label: "$37" },
  { value: "38", label: "$38" },
  { value: "39", label: "$39" },
  { value: "40", label: "$40" },
  { value: "41", label: "$41" },
  { value: "42", label: "$42" },
  { value: "43", label: "$43" },
  { value: "44", label: "$44" },
  { value: "45", label: "$45" },
  { value: "46", label: "$46" },
  { value: "47", label: "$47" },
  { value: "48", label: "$48" },
  { value: "49", label: "$49" },
  { value: "50", label: "$50" },
  { value: "51", label: "$51" },
  { value: "52", label: "$52" },
  { value: "53", label: "$53" },
  { value: "54", label: "$54" },
  { value: "55", label: "$55" },
  { value: "56", label: "$56" },
  { value: "57", label: "$57" },
  { value: "58", label: "$58" },
  { value: "59", label: "$59" },
  { value: "60", label: "$60" },
  { value: "61", label: "$61" },
  { value: "62", label: "$62" },
  { value: "63", label: "$63" },
  { value: "64", label: "$64" },
  { value: "65", label: "$65" },
  { value: "66", label: "$66" },
  { value: "67", label: "$67" },
  { value: "68", label: "$68" },
  { value: "69", label: "$69" },
  { value: "70", label: "$70" },
  { value: "71", label: "$71" },
  { value: "72", label: "$72" },
  { value: "73", label: "$73" },
  { value: "74", label: "$74" },
  { value: "75", label: "$75" },
  { value: "76", label: "$76" },
  { value: "77", label: "$77" },
  { value: "78", label: "$78" },
  { value: "79", label: "$79" },
  { value: "80", label: "$80" },
  { value: "81", label: "$81" },
  { value: "82", label: "$82" },
  { value: "83", label: "$83" },
  { value: "84", label: "$84" },
  { value: "85", label: "$85" },
  { value: "86", label: "$86" },
  { value: "87", label: "$87" },
  { value: "88", label: "$88" },
  { value: "89", label: "$89" },
  { value: "90", label: "$90" },
  { value: "91", label: "$91" },
  { value: "92", label: "$92" },
  { value: "93", label: "$93" },
  { value: "94", label: "$94" },
  { value: "95", label: "$95" },
  { value: "96", label: "$96" },
  { value: "97", label: "$97" },
  { value: "98", label: "$98" },
  { value: "99", label: "$99" },
  { value: "100", label: "$100" },
  { value: "101", label: "$101" },
  { value: "102", label: "$102" },
  { value: "103", label: "$103" },
  { value: "104", label: "$104" },
  { value: "105", label: "$105" },
  { value: "106", label: "$106" },
  { value: "107", label: "$107" },
  { value: "108", label: "$108" },
  { value: "109", label: "$109" },
  { value: "110", label: "$110" },
  { value: "111", label: "$111" },
  { value: "112", label: "$112" },
  { value: "113", label: "$113" },
  { value: "114", label: "$114" },
  { value: "115", label: "$115" },
  { value: "116", label: "$116" },
  { value: "117", label: "$117" },
  { value: "118", label: "$118" },
  { value: "119", label: "$119" },
  { value: "120", label: "$120" },
  { value: "121", label: "$121" },
  { value: "122", label: "$122" },
  { value: "123", label: "$123" },
  { value: "124", label: "$124" },
  { value: "125", label: "$125" },
  { value: "126", label: "$126" },
  { value: "127", label: "$127" },
  { value: "128", label: "$128" },
  { value: "129", label: "$129" },
  { value: "130", label: "$130" },
  { value: "131", label: "$131" },
  { value: "132", label: "$132" },
  { value: "133", label: "$133" },
  { value: "134", label: "$134" },
  { value: "135", label: "$135" },
  { value: "136", label: "$136" },
  { value: "137", label: "$137" },
  { value: "138", label: "$138" },
  { value: "139", label: "$139" },
  { value: "140", label: "$140" },
  { value: "141", label: "$141" },
  { value: "142", label: "$142" },
  { value: "143", label: "$143" },
  { value: "144", label: "$144" },
  { value: "145", label: "$145" },
  { value: "146", label: "$146" },
  { value: "147", label: "$147" },
  { value: "148", label: "$148" },
  { value: "149", label: "$149" },
  { value: "150", label: "$150" },
  { value: "151", label: "$151" },
  { value: "152", label: "$152" },
  { value: "153", label: "$153" },
  { value: "154", label: "$154" },
  { value: "155", label: "$155" },
  { value: "156", label: "$156" },
  { value: "157", label: "$157" },
  { value: "158", label: "$158" },
  { value: "159", label: "$159" },
  { value: "160", label: "$160" },
  { value: "161", label: "$161" },
  { value: "162", label: "$162" },
  { value: "163", label: "$163" },
  { value: "164", label: "$164" },
  { value: "165", label: "$165" },
  { value: "166", label: "$166" },
  { value: "167", label: "$167" },
  { value: "168", label: "$168" },
  { value: "169", label: "$169" },
  { value: "170", label: "$170" },
  { value: "171", label: "$171" },
  { value: "172", label: "$172" },
  { value: "173", label: "$173" },
  { value: "174", label: "$174" },
  { value: "175", label: "$175" },
  { value: "176", label: "$176" },
  { value: "177", label: "$177" },
  { value: "178", label: "$178" },
  { value: "179", label: "$179" },
  { value: "180", label: "$180" },
  { value: "181", label: "$181" },
  { value: "182", label: "$182" },
  { value: "183", label: "$183" },
  { value: "184", label: "$184" },
  { value: "185", label: "$185" },
  { value: "186", label: "$186" },
  { value: "187", label: "$187" },
  { value: "188", label: "$188" },
  { value: "189", label: "$189" },
  { value: "190", label: "$190" },
  { value: "191", label: "$191" },
  { value: "192", label: "$192" },
  { value: "193", label: "$193" },
  { value: "194", label: "$194" },
  { value: "195", label: "$195" },
  { value: "196", label: "$196" },
  { value: "197", label: "$197" },
  { value: "198", label: "$198" },
  { value: "199", label: "$199" },
  { value: "200", label: "$200" },
];

export const PercentageContstants = [
  { value: "1", label: "1%" },
  { value: "2", label: "2%" },
  { value: "3", label: "3%" },
  { value: "4", label: "4%" },
  { value: "5", label: "5%" },
  { value: "6", label: "6%" },
  { value: "7", label: "7%" },
  { value: "8", label: "8%" },
  { value: "9", label: "9%" },
  { value: "10", label: "10%" },
  { value: "11", label: "11%" },
  { value: "12", label: "12%" },
  { value: "13", label: "13%" },
  { value: "14", label: "14%" },
  { value: "15", label: "15%" },
  { value: "16", label: "16%" },
  { value: "17", label: "17%" },
  { value: "18", label: "18%" },
  { value: "19", label: "19%" },
  { value: "20", label: "20%" },
  { value: "21", label: "21%" },
  { value: "22", label: "22%" },
  { value: "23", label: "23%" },
  { value: "24", label: "24%" },
  { value: "25", label: "25%" },
  { value: "26", label: "26%" },
  { value: "27", label: "27%" },
  { value: "28", label: "28%" },
  { value: "29", label: "29%" },
  { value: "30", label: "30%" },
  { value: "31", label: "31%" },
  { value: "32", label: "32%" },
  { value: "33", label: "33%" },
  { value: "34", label: "34%" },
  { value: "35", label: "35%" },
  { value: "36", label: "36%" },
  { value: "37", label: "37%" },
  { value: "38", label: "38%" },
  { value: "39", label: "39%" },
  { value: "40", label: "40%" },
  { value: "41", label: "41%" },
  { value: "42", label: "42%" },
  { value: "43", label: "43%" },
  { value: "44", label: "44%" },
  { value: "45", label: "45%" },
  { value: "46", label: "46%" },
  { value: "47", label: "47%" },
  { value: "48", label: "48%" },
  { value: "49", label: "49%" },
  { value: "50", label: "50%" },
  { value: "51", label: "51%" },
  { value: "52", label: "52%" },
  { value: "53", label: "53%" },
  { value: "54", label: "54%" },
  { value: "55", label: "55%" },
  { value: "56", label: "56%" },
  { value: "57", label: "57%" },
  { value: "58", label: "58%" },
  { value: "59", label: "59%" },
  { value: "60", label: "60%" },
  { value: "61", label: "61%" },
  { value: "62", label: "62%" },
  { value: "63", label: "63%" },
  { value: "64", label: "64%" },
  { value: "65", label: "65%" },
  { value: "66", label: "66%" },
  { value: "67", label: "67%" },
  { value: "68", label: "68%" },
  { value: "69", label: "69%" },
  { value: "70", label: "70%" },
  { value: "71", label: "71%" },
  { value: "72", label: "72%" },
  { value: "73", label: "73%" },
  { value: "74", label: "74%" },
  { value: "75", label: "75%" },
  { value: "76", label: "76%" },
  { value: "77", label: "77%" },
  { value: "78", label: "78%" },
  { value: "79", label: "79%" },
  { value: "80", label: "80%" },
  { value: "81", label: "81%" },
  { value: "82", label: "82%" },
  { value: "83", label: "83%" },
  { value: "84", label: "84%" },
  { value: "85", label: "85%" },
  { value: "86", label: "86%" },
  { value: "87", label: "87%" },
  { value: "88", label: "88%" },
  { value: "89", label: "89%" },
  { value: "90", label: "90%" },
  { value: "91", label: "91%" },
  { value: "92", label: "92%" },
  { value: "93", label: "93%" },
  { value: "94", label: "94%" },
  { value: "95", label: "95%" },
  { value: "96", label: "96%" },
  { value: "97", label: "97%" },
  { value: "98", label: "98%" },
  { value: "99", label: "99%" },
  { value: "100", label: "100%" },
];

export const RadiusConstants = [
  { value: "1", label: "1m" },
  { value: "2", label: "2m" },
  { value: "3", label: "3m" },
  { value: "4", label: "4m" },
  { value: "5", label: "5m" },
  { value: "6", label: "6m" },
  { value: "7", label: "7m" },
  { value: "8", label: "8m" },
  { value: "9", label: "9m" },
  { value: "10", label: "10m" },
  { value: "11", label: "11m" },
  { value: "12", label: "12m" },
  { value: "13", label: "13m" },
  { value: "14", label: "14m" },
  { value: "15", label: "15m" },
  { value: "16", label: "16m" },
  { value: "17", label: "17m" },
  { value: "18", label: "18m" },
  { value: "19", label: "19m" },
  { value: "20", label: "20m" },
  { value: "21", label: "21m" },
  { value: "22", label: "22m" },
  { value: "23", label: "23m" },
  { value: "24", label: "24m" },
  { value: "25", label: "25m" },
  { value: "26", label: "26m" },
  { value: "27", label: "27m" },
  { value: "28", label: "28m" },
  { value: "29", label: "29m" },
  { value: "30", label: "30m" },
  { value: "31", label: "31m" },
  { value: "32", label: "32m" },
  { value: "33", label: "33m" },
  { value: "34", label: "34m" },
  { value: "35", label: "35m" },
  { value: "36", label: "36m" },
  { value: "37", label: "37m" },
  { value: "38", label: "38m" },
  { value: "39", label: "39m" },
  { value: "40", label: "40m" },
  { value: "41", label: "41m" },
  { value: "42", label: "42m" },
  { value: "43", label: "43m" },
  { value: "44", label: "44m" },
  { value: "45", label: "45m" },
  { value: "46", label: "46m" },
  { value: "47", label: "47m" },
  { value: "48", label: "48m" },
  { value: "49", label: "49m" },
  { value: "50", label: "50m" },
  { value: "51", label: "51m" },
  { value: "52", label: "52m" },
  { value: "53", label: "53m" },
  { value: "54", label: "54m" },
  { value: "55", label: "55m" },
  { value: "56", label: "56m" },
  { value: "57", label: "57m" },
  { value: "58", label: "58m" },
  { value: "59", label: "59m" },
  { value: "60", label: "60m" },
  { value: "61", label: "61m" },
  { value: "62", label: "62m" },
  { value: "63", label: "63m" },
  { value: "64", label: "64m" },
  { value: "65", label: "65m" },
  { value: "66", label: "66m" },
  { value: "67", label: "67m" },
  { value: "68", label: "68m" },
  { value: "69", label: "69m" },
  { value: "70", label: "70m" },
  { value: "71", label: "71m" },
  { value: "72", label: "72m" },
  { value: "73", label: "73m" },
  { value: "74", label: "74m" },
  { value: "75", label: "75m" },
  { value: "76", label: "76m" },
  { value: "77", label: "77m" },
  { value: "78", label: "78m" },
  { value: "79", label: "79m" },
  { value: "80", label: "80m" },
  { value: "81", label: "81m" },
  { value: "82", label: "82m" },
  { value: "83", label: "83m" },
  { value: "84", label: "84m" },
  { value: "85", label: "85m" },
  { value: "86", label: "86m" },
  { value: "87", label: "87m" },
  { value: "88", label: "88m" },
  { value: "89", label: "89m" },
  { value: "90", label: "90m" },
  { value: "91", label: "91m" },
  { value: "92", label: "92m" },
  { value: "93", label: "93m" },
  { value: "94", label: "94m" },
  { value: "95", label: "95m" },
  { value: "96", label: "96m" },
  { value: "97", label: "97m" },
  { value: "98", label: "98m" },
  { value: "99", label: "99m" },
  { value: "100", label: "100m" },
];

export const specialties = [
  {
    value: "Cardiovascular & Pulmonary Clinical Specialist (CCS)",
    label: "Cardiovascular & Pulmonary Clinical Specialist (CCS)",
  },
  {
    value: "Electrophysiology Clinical Specialist (ECS)",
    label: "Electrophysiology Clinical Specialist (ECS)",
  },

  {
    value: "Geriatric Clinical Specialist (GCS)",
    label: "Geriatric Clinical Specialist (GCS)",
  },
  {
    value: "Neurology Clinical Specialist (NCS)",
    label: "Neurology Clinical Specialist (NCS)",
  },
  {
    value: "Oncology Clinical Specialist",
    label: "Oncology Clinical Specialist",
  },
  {
    value: "Orthopedic Clinical Specialist (OCS)",
    label: "Orthopedic Clinical Specialist (OCS)",
  },

  {
    value: "Sports Clinical Specialist (SCS)",
    label: "Sports Clinical Specialist (SCS)",
  },
  {
    value: "Pediatric Clinical Specialist (PCS)",
    label: "Pediatric Clinical Specialist (PCS)",
  },

  {
    value: "Women’s Health Clinical Specialist (WCS)",
    label: "Women’s Health Clinical Specialist (WCS)",
  },
  {
    value: "Wound Management Clinician Specialist ",
    label: "Wound Management Clinician Specialist ",
  },
];

export const education = [
  { value: "2024", label: "2024" },
  { value: "2023", label: "2023" },
  { value: "2022", label: "2022" },
  { value: "2021", label: "2021" },
  { value: "2020", label: "2020" },
  { value: "2019", label: "2019" },
  { value: "2018", label: "2018" },
  { value: "2017", label: "2017" },
  { value: "2016", label: "2016" },
  { value: "2015", label: "2015" },
  { value: "2014", label: "2014" },
  { value: "2013", label: "2013" },
  { value: "2012", label: "2012" },
  { value: "2011", label: "2011" },
  { value: "2010", label: "2010" },
  { value: "2009", label: "2009" },
  { value: "2008", label: "2008" },
  { value: "2007", label: "2007" },
  { value: "2006", label: "2006" },
  { value: "2005", label: "2005" },
  { value: "2004", label: "2004" },
  { value: "2003", label: "2003" },
  { value: "2002", label: "2002" },
  { value: "2001", label: "2001" },
  { value: "2000", label: "2000" },
  { value: "1999", label: "1999" },
  { value: "1998", label: "1998" },
  { value: "1997", label: "1997" },
  { value: "1996", label: "1996" },
  { value: "1995", label: "1995" },
  { value: "1994", label: "1994" },
  { value: "1993", label: "1993" },
  { value: "1992", label: "1992" },
  { value: "1991", label: "1991" },
  { value: "1990", label: "1990" },
  { value: "1989", label: "1989" },
  { value: "1988", label: "1988" },
  { value: "1987", label: "1987" },
  { value: "1986", label: "1986" },
  { value: "1985", label: "1985" },
  { value: "1984", label: "1984" },
  { value: "1983", label: "1983" },
  { value: "1982", label: "1982" },
  { value: "1981", label: "1981" },
  { value: "1980", label: "1980" },
  { value: "1979", label: "1979" },
  { value: "1978", label: "1978" },
  { value: "1977", label: "1977" },
  { value: "1976", label: "1976" },
  { value: "1975", label: "1975" },
  { value: "1974", label: "1974" },
  { value: "1973", label: "1973" },
  { value: "1972", label: "1972" },
  { value: "1971", label: "1971" },
  { value: "1970", label: "1970" },
];

export const experience = [
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
  { value: 16, label: "16" },
  { value: 17, label: "17" },
  { value: 18, label: "18" },
  { value: 19, label: "19" },
  { value: 20, label: "20" },
  { value: 21, label: "21" },
  { value: 22, label: "22" },
  { value: 23, label: "23" },
  { value: 24, label: "24" },
  { value: 25, label: "25" },
  { value: 26, label: "26" },
  { value: 27, label: "27" },
  { value: 28, label: "28" },
  { value: 29, label: "29" },
  { value: 30, label: "30" },
  { value: 31, label: "31" },
  { value: 32, label: "32" },
  { value: 33, label: "33" },
  { value: 34, label: "34" },
  { value: 35, label: "35" },
  { value: 36, label: "36" },
  { value: 37, label: "37" },
  { value: 38, label: "38" },
  { value: 39, label: "39" },
  { value: 40, label: "40" },
  { value: 41, label: "41" },
  { value: 42, label: "42" },
  { value: 43, label: "43" },
  { value: 44, label: "44" },
  { value: 45, label: "45" },
  { value: 46, label: "46" },
  { value: 47, label: "47" },
  { value: 48, label: "48" },
  { value: 49, label: "49" },
  { value: 50, label: "50" },
];

export const certificationYear = [
  { value: "2024", label: "2024" },
  { value: "2023", label: "2023" },
  { value: "2022", label: "2022" },
  { value: "2021", label: "2021" },
  { value: "2020", label: "2020" },
  { value: "2019", label: "2019" },
  { value: "2018", label: "2018" },
  { value: "2017", label: "2017" },
  { value: "2016", label: "2016" },
  { value: "2015", label: "2015" },
  { value: "2014", label: "2014" },
  { value: "2013", label: "2013" },
  { value: "2012", label: "2012" },
  { value: "2011", label: "2011" },
  { value: "2010", label: "2010" },
  { value: "2009", label: "2009" },
  { value: "2008", label: "2008" },
  { value: "2007", label: "2007" },
  { value: "2006", label: "2006" },
  { value: "2005", label: "2005" },
  { value: "2004", label: "2004" },
  { value: "2003", label: "2003" },
  { value: "2002", label: "2002" },
  { value: "2001", label: "2001" },
  { value: "2000", label: "2000" },
  { value: "1999", label: "1999" },
  { value: "1998", label: "1998" },
  { value: "1997", label: "1997" },
  { value: "1996", label: "1996" },
  { value: "1995", label: "1995" },
  { value: "1994", label: "1994" },
  { value: "1993", label: "1993" },
  { value: "1992", label: "1992" },
  { value: "1991", label: "1991" },
  { value: "1990", label: "1990" },
  { value: "1989", label: "1989" },
  { value: "1988", label: "1988" },
  { value: "1987", label: "1987" },
  { value: "1986", label: "1986" },
  { value: "1985", label: "1985" },
  { value: "1984", label: "1984" },
  { value: "1983", label: "1983" },
  { value: "1982", label: "1982" },
  { value: "1981", label: "1981" },
  { value: "1980", label: "1980" },
  { value: "1979", label: "1979" },
  { value: "1978", label: "1978" },
  { value: "1977", label: "1977" },
  { value: "1976", label: "1976" },
  { value: "1975", label: "1975" },
  { value: "1974", label: "1974" },
  { value: "1973", label: "1973" },
  { value: "1972", label: "1972" },
  { value: "1971", label: "1971" },
  { value: "1970", label: "1970" },
];
export const specialtiesYear = [
  { value: "2024", label: "2024" },
  { value: "2023", label: "2023" },
  { value: "2022", label: "2022" },
  { value: "2021", label: "2021" },
  { value: "2020", label: "2020" },
  { value: "2019", label: "2019" },
  { value: "2018", label: "2018" },
  { value: "2017", label: "2017" },
  { value: "2016", label: "2016" },
  { value: "2015", label: "2015" },
  { value: "2014", label: "2014" },
  { value: "2013", label: "2013" },
  { value: "2012", label: "2012" },
  { value: "2011", label: "2011" },
  { value: "2010", label: "2010" },
  { value: "2009", label: "2009" },
  { value: "2008", label: "2008" },
  { value: "2007", label: "2007" },
  { value: "2006", label: "2006" },
  { value: "2005", label: "2005" },
  { value: "2004", label: "2004" },
  { value: "2003", label: "2003" },
  { value: "2002", label: "2002" },
  { value: "2001", label: "2001" },
  { value: "2000", label: "2000" },
  { value: "1999", label: "1999" },
  { value: "1998", label: "1998" },
  { value: "1997", label: "1997" },
  { value: "1996", label: "1996" },
  { value: "1995", label: "1995" },
  { value: "1994", label: "1994" },
  { value: "1993", label: "1993" },
  { value: "1992", label: "1992" },
  { value: "1991", label: "1991" },
  { value: "1990", label: "1990" },
  { value: "1989", label: "1989" },
  { value: "1988", label: "1988" },
  { value: "1987", label: "1987" },
  { value: "1986", label: "1986" },
  { value: "1985", label: "1985" },
  { value: "1984", label: "1984" },
  { value: "1983", label: "1983" },
  { value: "1982", label: "1982" },
  { value: "1981", label: "1981" },
  { value: "1980", label: "1980" },
  { value: "1979", label: "1979" },
  { value: "1978", label: "1978" },
  { value: "1977", label: "1977" },
  { value: "1976", label: "1976" },
  { value: "1975", label: "1975" },
  { value: "1974", label: "1974" },
  { value: "1973", label: "1973" },
  { value: "1972", label: "1972" },
  { value: "1971", label: "1971" },
  { value: "1970", label: "1970" },
];
export const gender = [
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
];

export const months = [
  { value: "01", label: "1" },
  { value: "02", label: "2" },
  { value: "03", label: "3" },
  { value: "04", label: "4" },
  { value: "05", label: "5" },
  { value: "06", label: "6" },
  { value: "07", label: "7" },
  { value: "08", label: "8" },
  { value: "09", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
];
export const years = () => {
  const currentYear = new Date().getFullYear();
  const twentyYears = [{ value: `${currentYear}`, label: `${currentYear}` }];
  for (let i = 1; i < 20; i++) {
    const nextYears = `${new Date(
      new Date().setFullYear(new Date().getFullYear() + i),
    ).getFullYear()}`;
    twentyYears.push({ value: nextYears, label: nextYears });
  }

  return twentyYears;
};
export const ACCOUNT_HOLDER_TYPE = [
  { key: 1, value: "individual", label: "Individual" },
  { key: 2, value: "company", label: "Company" },
];

export const language = [
  { value: "English", label: "English" },
  { value: "Afrikaans", label: "Afrikaans" },
  { value: "Albanian", label: "Albanian" },
  {
    value: "American Sign Language (ASL)",
    label: "American Sign Language (ASL)",
  },
  { value: "Arabic", label: "Arabic" },
  { value: "Armenian", label: "Armenian" },
  { value: "Basque", label: "Basque" },
  { value: "Bengali", label: "Bengali" },
  { value: "Bulgarian", label: "Bulgarian" },
  { value: "Catalan", label: "Catalan" },
  { value: "Cambodian", label: "Cambodian" },
  { value: "Chinese (Mandarin)", label: "Chinese (Mandarin)" },
  { value: "Croatian", label: "Croatian" },
  { value: "Czech", label: "Czech" },
  { value: "Danish", label: "Danish" },
  { value: "Dutch", label: "Dutch" },
  { value: "Estonian", label: "Estonian" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finnish", label: "Finnish" },
  { value: "French", label: "French" },
  { value: "Georgian", label: "Georgian" },
  { value: "German", label: "German" },
  { value: "Greek", label: "Greek" },
  { value: "Gujarati", label: "Gujarati" },
  { value: "Hebrew", label: "Hebrew" },
  { value: "Hindi", label: "Hindi" },
  { value: "Hungarian", label: "Hungarian" },
  { value: "Icelandic", label: "Icelandic" },
  { value: "Indonesian", label: "Indonesian" },
  { value: "Irish", label: "Irish" },
  { value: "Italian", label: "Italian" },
  { value: "Japanese", label: "Japanese" },
  { value: "Javanese", label: "Javanese" },
  { value: "Korean", label: "Korean" },
  { value: "Latin", label: "Latin" },
  { value: "Latvian", label: "Latvian" },
  { value: "Lithuanian", label: "Lithuanian" },
  { value: "Macedonian", label: "Macedonian" },
  { value: "Malay", label: "Malay" },
  { value: "Malayalam", label: "Malayalam" },
  { value: "Maltese", label: "Maltese" },
  { value: "Maori", label: "Maori" },
  { value: "Marathi", label: "Marathi" },
  { value: "Mongolian", label: "Mongolian" },
  { value: "Nepali", label: "Nepali" },
  { value: "Norwegian", label: "Norwegian" },
  { value: "Persian", label: "Persian" },
  { value: "Polish", label: "Polish" },
  { value: "Portuguese", label: "Portuguese" },
  { value: "Punjabi", label: "Punjabi" },
  { value: "Quechua", label: "Quechua" },
  { value: "Romanian", label: "Romanian" },
  { value: "Russian", label: "Russian" },
  { value: "Samoan", label: "Samoan" },
  { value: "Serbian", label: "Serbian" },
  { value: "Slovak", label: "Slovak" },
  { value: "Slovenian", label: "Slovenian" },
  { value: "Spanish", label: "Spanish" },
  { value: "Swahili", label: "Swahili" },
  { value: "Swedish", label: "Swedish" },
  { value: "Tamil", label: "Tamil" },
  { value: "Tatar", label: "Tatar" },
  { value: "Telugu", label: "Telugu" },
  { value: "Thai", label: "Thai" },
  { value: "Tibetan", label: "Tibetan" },
  { value: "Tonga", label: "Tonga" },
  { value: "Turkish", label: "Turkish" },
  { value: "Ukrainian", label: "Ukrainian" },
  { value: "Urdu", label: "Urdu" },
  { value: "Uzbek", label: "Uzbek" },
  { value: "Vietnamese", label: "Vietnamese" },
  { value: "Welsh", label: "Welsh" },
  { value: "Xhosa", label: "Xhosa" },
];

export const clinician = [
  { value: "pt", label: "PT" },
  { value: "pta", label: "PTA" },
  { value: "ot", label: "OT" },
  { value: "ota", label: "OTA" },
  { value: "cota", label: "COTA" },
  { value: "slp", label: "SLP" },
];

export const state = [
  { value: "Alabama", label: "Alabama", stateCode: "AL" },
  { value: "Alaska", label: "Alaska", stateCode: "AK" },
  { value: "Arizona", label: "Arizona", stateCode: "AZ" },
  { value: "Arkansas", label: "Arkansas", stateCode: "AR" },
  { value: "California", label: "California", stateCode: "CA" },
  { value: "Colorado", label: "Colorado", stateCode: "CO" },
  { value: "Connecticut", label: "Connecticut", stateCode: "CT" },
  { value: "Delaware", label: "Delaware", stateCode: "DE" },
  { value: "Florida", label: "Florida", stateCode: "FL" },
  { value: "Georgia", label: "Georgia", stateCode: "GA" },
  { value: "Hawaii", label: "Hawaii", stateCode: "HI" },
  { value: "Idaho", label: "Idaho", stateCode: "ID" },
  { value: "Illinois", label: "Illinois", stateCode: "IL" },
  { value: "Indiana", label: "Indiana", stateCode: "IN" },
  { value: "Iowa", label: "Iowa", stateCode: "IA" },
  { value: "Kansas", label: "Kansas", stateCode: "KS" },
  { value: "Kentucky", label: "Kentucky", stateCode: "KY" },
  { value: "Louisiana", label: "Louisiana", stateCode: "LA" },
  { value: "Maine", label: "Maine", stateCode: "ME" },
  { value: "Maryland", label: "Maryland", stateCode: "MD" },
  { value: "Massachusetts", label: "Massachusetts", stateCode: "MA" },
  { value: "Michigan", label: "Michigan", stateCode: "MI" },
  { value: "Minnesota", label: "Minnesota", stateCode: "MN" },
  { value: "Mississippi", label: "Mississippi", stateCode: "MS" },
  { value: "Missouri", label: "Missouri", stateCode: "MO" },
  { value: "Montana", label: "Montana", stateCode: "MT" },
  { value: "Nebraska", label: "Nebraska", stateCode: "NE" },
  { value: "Nevada", label: "Nevada", stateCode: "NV" },
  { value: "New Hampshire", label: "New Hampshire", stateCode: "NH" },
  { value: "New Jersey", label: "New Jersey", stateCode: "NJ" },
  { value: "New Mexico", label: "New Mexico", stateCode: "NM" },
  { value: "New York", label: "New York", stateCode: "NY" },
  { value: "North Carolina", label: "North Carolina", stateCode: "NC" },
  { value: "North Dakota", label: "North Dakota", stateCode: "ND" },
  { value: "Ohio", label: "Ohio", stateCode: "OH" },
  { value: "Oklahoma", label: "Oklahoma", stateCode: "OK" },
  { value: "Oregon", label: "Oregon", stateCode: "OR" },
  { value: "Pennsylvania", label: "Pennsylvania", stateCode: "PA" },
  { value: "Rhode Island", label: "Rhode Island", stateCode: "RI" },
  { value: "South Carolina", label: "South Carolina", stateCode: "SC" },
  { value: "South Dakota", label: "South Dakota", stateCode: "SD" },
  { value: "Tennessee", label: "Tennessee", stateCode: "TN" },
  { value: "Texas", label: "Texas", stateCode: "TX" },
  { value: "Utah", label: "Utah", stateCode: "UT" },
  { value: "Vermont", label: "Vermont", stateCode: "VT" },
  { value: "Virginia", label: "Virginia", stateCode: "VA" },
  { value: "Washington", label: "Washington", stateCode: "WA" },
  { value: "West Virginia", label: "West Virginia", stateCode: "WV" },
  { value: "Wisconsin", label: "Wisconsin", stateCode: "WI" },
  { value: "Wyoming", label: "Wyoming", stateCode: "WY" },
];

export const travelMile = [
  { value: "5", label: "Within 5 miles" },
  { value: "10", label: "Within 10 miles" },
  { value: "15", label: "Within 15 miles" },
  { value: "20", label: "Within 20 miles" },
  { value: "30", label: "Within 30 miles" },
  { value: "45", label: "Within 45 miles" },
  { value: "60", label: "Within 60 miles" },
];

export const timeOptions = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
  { value: "32", label: "32" },
  { value: "33", label: "33" },
  { value: "34", label: "34" },
  { value: "35", label: "35" },
  { value: "36", label: "36" },
  { value: "37", label: "37" },
  { value: "38", label: "38" },
  { value: "39", label: "39" },
  { value: "40", label: "40" },
  { value: "41", label: "41" },
  { value: "42", label: "42" },
  { value: "43", label: "43" },
  { value: "44", label: "44" },
  { value: "45", label: "45" },
  { value: "46", label: "46" },
  { value: "47", label: "47" },
  { value: "48", label: "48" },
  { value: "49", label: "49" },
  { value: "50", label: "50" },
];

export const refBy = [
  { label: "Web search", value: "WebSearch" },
  { label: "Flyer", value: "Flyer" },
  { label: "Job board", value: "JobBoard" },
  { label: "Referral", value: "Referral" },
  { label: "Others:", value: "Others" },
];

export const facilityTypeOptions = [
  { value: "Outpatient", label: "Outpatient" },
  { value: "Inpatient", label: "Inpatient" },
  { value: "SNF", label: "SNF" },
  { value: "LTACH", label: "LTACH" },
  { value: "Home Health", label: "Home Health" },
  { value: "Pediatrics", label: "Pediatrics" },
  { value: "Aquatics", label: "Aquatics" },
  { value: "Other", label: "Other:" },
];

export const clinicianFacilityTypeOptions = [
  { value: "Outpatient", label: "Outpatient" },
  { value: "Inpatient", label: "Inpatient" },
  { value: "SNF", label: "SNF" },
  { value: "LTACH", label: "LTACH" },
  { value: "Home Health", label: "Home Health" },
  { value: "Pediatrics", label: "Pediatrics" },
  { value: "Aquatics", label: "Aquatics" },
];
export const clinicianNotificationType = [
  { value: "email_notification", label: "Email" },
  { value: "sms_notification", label: "SMS" },
  { value: "push_notification", label: "App" },
]

export const patientTypeOptions = [
  { value: "Ortho", label: "Ortho" },
  { value: "Sports", label: "Sports" },
  { value: "Pediatrics", label: "Pediatrics" },
  { value: "Neuro", label: "Neuro" },
  { value: "Acute care", label: "Acute care" },
  { value: "Geriatric", label: "Geriatric" },
  { value: "Other", label: "Other:" },
];

export const dressCodeOptions = [
  { value: "Business Casual", label: "Business Casual" },
  { value: "Scrubs", label: "Scrubs" },
  { value: "Athletic", label: "Athletic" },
  { value: "Other", label: "Other:" },
];

export const emrListOptions = [
  { value: "Clinicient", label: "Clinicient" },
  { value: "Kareo", label: "Kareo" },
  { value: "TheraOffice", label: "TheraOffice" },
  { value: "Epic", label: "Epic" },
  { value: "Raintree", label: "Raintree" },
  { value: "WebPT", label: "WebPT" },
  { value: "Other", label: "Other:" },
];

export const boolList = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const facilityExp = [
  {
    day: "Outpatient",
    startTime: null,
  },
  {
    day: "Inpatient",
  },
  {
    day: "Sports Facility",
  },
  {
    day: "Acute Care",
  },
  {
    day: "Rehab Facility",
  },
  {
    day: "SNF",
  },
  {
    day: "LTACH",
  },
];

export const facExp = [
  {
    day: "Outpatient",
    startTime: "0",
    isAvailable: false,
  },
  {
    day: "Home Health",
    startTime: "0",
    isAvailable: false,
  },
  {
    day: "Inpatient",
    startTime: "0",
    isAvailable: false,
  },
  {
    day: "Sports Facility",
    startTime: "0",
    isAvailable: false,
  },
  {
    day: "Acute Care",
    startTime: "0",
    isAvailable: false,
  },
  {
    day: "Rehab Facility",
    startTime: "0",
    isAvailable: false,
  },
  {
    day: "SNF",
    startTime: "0",
    isAvailable: false,
  },
  {
    day: "LTACH",
    startTime: "0",
    isAvailable: false,
  },
];

export const patientExp = [
  {
    day: "Ortho",
    startTime: "0",
    isAvailable: false,
  },
  {
    day: "Sports",
    startTime: "0",
    isAvailable: false,
  },
  {
    day: "Pediatrics",
    startTime: "0",
    isAvailable: false,
  },
  {
    day: "Neuro",
    startTime: "0",
    isAvailable: false,
  },
  {
    day: "Acute Care",
    startTime: "0",
    isAvailable: false,
  },
  {
    day: "Geriatric",
    startTime: "0",
    isAvailable: false,
  },
  {
    day: "Pelvic Health",
    startTime: "0",
    isAvailable: false,
  },
  {
    day: "Wound Care",
    startTime: "0",
    isAvailable: false,
  },
  {
    day: "Oncology",
    startTime: "0",
    isAvailable: false,
  },
  {
    day: "Lymphedema",
    startTime: "0",
    isAvailable: false,
  },
];

export const weekDays = [
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
  { label: "Sunday", value: "sunday" },
];

export const vaccination = [
  {
    value: "I am vaccinated",
    label: "I am vaccinated",
  },
  {
    value: "I am not vaccinated but have an exemption",
    label: "I am not vaccinated but have an exemption",
  },
  {
    value: "I am not vaccinated and do not have an exemption",
    label: "I am not vaccinated and do not have an exemption",
  },
];
export const ClinicianJobSortOptions = [
  { label: "None", value: "" },
  { label: "Hourly rate", value: "hourlyRate" },
  { label: "Distance", value: "distance" },
  // { label: "Clinician type", value: "clinicianType" },
  { label: "Facility type", value: "facilityType" },
];

export const UserTypes = [
  { label: "I’m a Facility", value: "facility" },
  { label: "I’m a PT", value: "pt" },
  { label: "I’m a PTA", value: "pta" },
  { label: "I'm an OT", value: "ot" },
  { label: "I'm a COTA", value: "cota" },
  { label: "I'm a SLP", value: "slp" },
];
/**
 * const SignUpRoles = {
    Facility: 'facility',
    PT: 'pt',
    PTA: 'pta',
    Clinicians: "clinicians",
    Admin: "admin",
    OT: "ot",
    OTA: "ota",
    COTA: "cota",
    SLP: "slp",
    SLPA:"slpa"
}
 */
export const adminStatus = [
  { label: "All", value: "" },
  { label: "Approved", value: "approved" },
  { label: "Pending", value: "pending" },
  { label: "Incomplete", value: "incomplete" },
  { label: "Deactivated", value: "deactivate" },
];

export const adminUserTypes = [
  // { label: "All", value: "" },
  { label: "Facility", value: "facility" },
  { label: "PT", value: "pt" },
  { label: "PTA", value: "pta" },
  { label: "OT", value: "ot" },
  { label: "OTA", value: "ota" },
  { label: "SLP", value: "slp" },
  { label: "COTA", value: "cota" },
];

export const CANCELLED_PAYMENT_STATUS = "-";

export const paymentStatus = [
  // { label: "-", value: "-", disabled: true },
  { label: "In Progress", value: "inProcess" },
  { label: "Invoice", value: "invoice" },
  { label: "Payment Due", value: "paymentDue" },
  { label: "Completed", value: "completed" },
];

export const paymentStatusFac = [
  { label: "All", value: "" },
  { label: "-", value: "-" },
  { label: "In Progress", value: "inProcess" },
  { label: "Invoice", value: "invoice" },
  { label: "Payment Due", value: "paymentDue" },
  { label: "Completed", value: "completed" },
];
export const confirmShiftsSortBy = [
  { label: "All", value: "" },
  { label: "Shift Id", value: "jobId" },
  { label: "Facility", value: "officeName" },
  { label: "Nickname", value: "nickname" },
  { label: "Location", value: "location" },
  { label: "Shift Status", value: "status" },
  { label: "Shift Date", value: "jobDate" },
  { label: "Time worked", value: "totalTime" },
];
export const jobListingsSortBy = [
  { label: "All", value: "" },
  { label: "Shift ID", value: "serialNumber" },
  { label: "Location", value: "jobAddressNickname" },
  { label: "Day & Time (Original)", value: "day1" },
  { label: "Day & Time (Proposed)", value: "day2" },
  { label: "Application Details", value: "applicationDetails" },
  { label: "Clinician Type", value: "clinicianType" },
  { label: "$/Hr", value: "hourlyRate" },
  // { label: "Date Posted", value: "createdAt" },
  { label: "No. Of Applicants", value: "numberOfApplicants" },
  { label: "Est Amount", value: "totalPay" },
];

export const initShiftStatus = [
  { label: "Applied", value: "applied" },
  { label: "Completed", value: "completed" },
  { label: "Upcoming", value: "upcoming" },
];
export const shiftStatus = [
  { label: "All", value: "" },
  { label: "Applied", value: "applied" },
  { label: "Completed", value: "completed" },
  { label: "Upcoming", value: "upcoming" },
  { label: "Current", value: "current" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Declined", value: "declined" },
  { label: "Expired", value: "expired" },
];

export const statusVerify = [
  { label: "All", value: "" },
  { label: "Verified", value: "verified" },
  { label: "Not verified", value: "notVerified" },
  // { label: "Declined", value: "rejected" },
];

export const jobStatus = [
  { label: "All", value: "" },
  { label: "Confirmed", value: "confirmed" },
  { label: "Posted", value: "posted" },
  { label: "Completed", value: "completed" },
  { label: "Expired", value: "expired" },
  { label: "Deleted", value: "deleted" },
];

export const jobPostStatus = [
  { label: "All", value: "" },
  { label: "Confirmed", value: "confirmed" },
  { label: "Posted", value: "posted" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Completed", value: "completed" },
  { label: "Expired", value: "expired" },
  { label: "Deleted", value: "deleted" },
];

export const defaultMetroAreaFilters = [{ label: "All", value: "" }];

export const jobBoardApplicationStatus = [
  { label: "All", value: "" },
  { label: "Confirmed", value: "confirmed" },
  { label: "Pending", value: "pending" },
  { label: "Declined", value: "declined" },
];

export const breakMinsOptions = [
  { label: "0 mins", value: "0" },
  { label: "1 min", value: "1" },
  { label: "2 mins", value: "2" },
  { label: "3 mins", value: "3" },
  { label: "4 mins", value: "4" },
  { label: "5 mins", value: "5" },
  { label: "6 mins", value: "6" },
  { label: "7 mins", value: "7" },
  { label: "8 mins", value: "8" },
  { label: "9 mins", value: "9" },
  { label: "10 mins", value: "10" },
  { label: "11 mins", value: "11" },
  { label: "12 mins", value: "12" },
  { label: "13 mins", value: "13" },
  { label: "14 mins", value: "14" },
  { label: "15 mins", value: "15" },
  { label: "16 mins", value: "16" },
  { label: "17 mins", value: "17" },
  { label: "18 mins", value: "18" },
  { label: "19 mins", value: "19" },
  { label: "20 mins", value: "20" },
  { label: "21 mins", value: "21" },
  { label: "22 mins", value: "22" },
  { label: "23 mins", value: "23" },
  { label: "24 mins", value: "24" },
  { label: "25 mins", value: "25" },
  { label: "26 mins", value: "26" },
  { label: "27 mins", value: "27" },
  { label: "28 mins", value: "28" },
  { label: "29 mins", value: "29" },
  { label: "30 mins", value: "30" },
  { label: "31 mins", value: "31" },
  { label: "32 mins", value: "32" },
  { label: "33 mins", value: "33" },
  { label: "34 mins", value: "34" },
  { label: "35 mins", value: "35" },
  { label: "36 mins", value: "36" },
  { label: "37 mins", value: "37" },
  { label: "38 mins", value: "38" },
  { label: "39 mins", value: "39" },
  { label: "40 mins", value: "40" },
  { label: "41 mins", value: "41" },
  { label: "42 mins", value: "42" },
  { label: "43 mins", value: "43" },
  { label: "44 mins", value: "44" },
  { label: "45 mins", value: "45" },
  { label: "46 mins", value: "46" },
  { label: "47 mins", value: "47" },
  { label: "48 mins", value: "48" },
  { label: "49 mins", value: "49" },
  { label: "50 mins", value: "50" },
  { label: "51 mins", value: "51" },
  { label: "52 mins", value: "52" },
  { label: "53 mins", value: "53" },
  { label: "54 mins", value: "54" },
  { label: "55 mins", value: "55" },
  { label: "56 mins", value: "56" },
  { label: "57 mins", value: "57" },
  { label: "58 mins", value: "58" },
  { label: "59 mins", value: "59" },
];

export const breakHoursOptions = [
  { label: "0 Hr", value: "0" },
  { label: "1 Hr", value: "1" },
  { label: "2 Hrs", value: "2" },
  { label: "3 Hrs", value: "3" },
  { label: "4 Hrs", value: "4" },
  { label: "5 Hrs", value: "5" },
  { label: "6 Hrs", value: "6" },
  { label: "7 Hrs", value: "7" },
  { label: "8 Hrs", value: "8" },
  { label: "9 Hrs", value: "9" },
  { label: "10 Hrs", value: "10" },
  { label: "11 Hrs", value: "11" },
  { label: "12 Hrs", value: "12" },
];

export const notificationFreqOptions = [
  { label: "All", value: 2 },
  { label: "2x/week", value: 3 },
  { label: "1x/day", value: 1 },
  { label: "None", value: 0 },
];



export const invoiceMethodOptions = [
  { label: "Paper check", value: "Paper check" },
  { label: "ACH/Online bank transfer", value: "Online bank transfer" },
  { label: "Credit card*", value: "Credit card" },
];
